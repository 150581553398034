import React from "react"
import { navigate } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

export const withAuthentication =
  (Component: React.FC<any>) =>
  ({ location, ...props }: any) => {
    const {
      helpers: { isBrowser, storage },
    } = useCore()

    const {
      settings: { keys, params, routes },
    } = useConfigContext()

    const customerTokens = storage.get(keys.customer)

    if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
      storage.remove(keys.customer)
      if (isBrowser) {
        const route =
          location?.pathname === routes.WISHLIST
            ? routes.SAVED
            : `${routes.LOGIN}${location.pathname !== routes.DASHBOARD ? `?${params?.continue}=${location.pathname}` : ""}`
        navigate(route, { replace: true })
      }
    }

    return <Component location={location} {...props} />
  }

export const withoutAuthentication =
  (Component: React.FC<any>) =>
  ({ ...props }: any) => {
    const {
      helpers: { isBrowser, storage },
    } = useCore()
    const {
      settings: { keys, routes },
    } = useConfigContext()

    const customerTokens = storage.get(keys.customer)

    if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
      if (isBrowser) {
        const route = location?.pathname === routes.SAVED ? routes.WISHLIST : routes.DASHBOARD
        navigate(route, { replace: true })
      }
    }

    return <Component {...props} />
  }
