import React from "react"
import { Container, Box } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import AccountRegisterForm from "./AccountRegisterForm"
import type { Props } from "@app/pages/account/register"
import type { PageProps } from "@root/types/global"
import Title from "@app/components/Sections/Title/Title"

const AccountRegister: React.FC<PageProps<Props>> = ({ page }) => {
  const content = useContent(page)
  return (
    <>
      <Title title={page?.title} />
      <Container maxW="md" pb={16}>
        <Box as="section" pb={8} textAlign="center">
          {content}
        </Box>
        <AccountRegisterForm />
      </Container>
    </>
  )
}

export default AccountRegister
