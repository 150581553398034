import React, { useState } from "react"
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  IconButton,
  Stack,
  Box,
  Checkbox,
} from "@chakra-ui/react"
import { BiShow, BiHide } from "react-icons/bi"
import { useCustomerRegister } from "@app/hooks/useCustomer"
import { graphql, useStaticQuery } from "gatsby"

const AccountRegisterForm: React.FC = () => {
  const { handleSubmit, handleChange, data, loading, errors } = useCustomerRegister()
  const [show, setShow] = useState(false)

  const { additionalContent } = useStaticQuery<GatsbyTypes.StaticRegisterPageAdditionalContentQuery>(graphql`
    query StaticRegisterPageAdditionalContent {
      additionalContent: sanityPageAccountRegister {
        additionalSubmitButton
      }
    }
  `)

  const { additionalSubmitButton } = additionalContent || {}

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8}>
      <Stack spacing={5}>
        <FormControl id="firstName" value={data?.firstName} onChange={handleChange} isRequired>
          <FormLabel>Firstname</FormLabel>
          <Input name="firstName" />
        </FormControl>
        <FormControl id="lastName" value={data?.lastName} onChange={handleChange} isRequired>
          <FormLabel>Lastname</FormLabel>
          <Input name="lastName" />
        </FormControl>

        <FormControl id="email" value={data?.email} onChange={handleChange} isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="email" type="email" />
        </FormControl>

        <FormControl id="password" value={data?.password} onChange={handleChange} isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input name="password" type={show ? "text" : "password"} />
            <InputRightElement>
              <IconButton
                variant="ghost"
                onClick={() => setShow(!show)}
                aria-label={`${show ? "Show" : "Hide"} password`}
                size="md"
                icon={show ? <BiHide /> : <BiShow />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl id="acceptsMarketing" value={data?.acceptsMarketing} onChange={handleChange}>
          <Checkbox name="acceptsMarketing" defaultIsChecked checked={!!data?.acceptsMarketing}>
            Subscribe to newsletter
          </Checkbox>
        </FormControl>

        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {additionalSubmitButton}
        </Button>

        {errors?.length > 0 && (
          <FormControl id="error" isInvalid>
            {errors?.map(error => (
              <FormErrorMessage key={error.toString()}>{error}</FormErrorMessage>
            ))}
          </FormControl>
        )}
      </Stack>
    </Box>
  )
}

export default React.memo(AccountRegisterForm)
